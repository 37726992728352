import React from "react";
import {Navbar, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faFacebook, faGooglePlay, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import "../style/main.css";


const AppFooter = () => {
  // const colorVariant = Capacitor.isNative ? "dark" : "light";
  const colorVariant = "dark";
  return (
    <Navbar
      sticky="top"
      bg={colorVariant}
      variant={colorVariant}
      className="footer"
    >
      <Row style={{ textAlign: "center", display: "block", flex: 1 }}>
        <div>
          <>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.facebook.com/DERBY-AAL-NAGIB-101202794592601"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                color={"#fff"}
                size={"2x"}
                className="facebookIcon"
              />
            </a>
            <span>{"  "}</span>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCBorRhG2touJMA2yw3sGNtw"
            >
              <FontAwesomeIcon icon={faYoutube} color={"#fff"} size={"2x"} className="youtubeIcon"/>
            </a>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://twitter.com/DerbyAllNagib"
            >
              <FontAwesomeIcon icon={faTwitter} color={"#fff"} size={"2x"} className="twitterIcon"/>
            </a>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.instagram.com/derbyallnagib/"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                color={"#fff"}
                size={"2x"}
                className="instaIcon"
              />
            </a>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=app.nagib.derby"
            >
              <FontAwesomeIcon
                icon={faGooglePlay}
                color={"#fff"}
                size={"2x"}
                className="playIcon"
              />
            </a>
            <a
              style={{ margin: 10 }}
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.instagram.com/derbyallnagib/"
            >
              <FontAwesomeIcon
                icon={faApple}
                color={"#fff"}
                size={"2x"}
                className="appleIcon"
              />
            </a>
          </>

          <br />
          <Navbar.Text className={"text-nowrap"} style={{ padding: 5 }}>
            Website created by Alpha SM © 2020
          </Navbar.Text>
        </div>
      </Row>
    </Navbar>
  );
};
export { AppFooter };
