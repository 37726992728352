import React, {useState} from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import "App.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneSquareAlt} from "@fortawesome/free-solid-svg-icons"
import {Form as FormikForm, Formik} from "formik";
import * as yup from "yup";
import axios from 'axios'

const schema = yup.object({
  Subject: yup.string().required(),
  From: yup
    .string()
    .email()
    .required(),
  phone: yup.string(),
  Body: yup.string().required()
});
const Enquiries = () => {
  const [isSubmitted, setSubmitted] = useState(false);

  const onSubmit = (event: any) => {
    console.log("Handle submit event", event);
      axios.post('https://derbyaalnagib.com/api/Email',event)
      .then(res=>{setTimeout(() => {
        setSubmitted(true);
      }, 1500);})
      .catch(()=>{console.log('msg not sent')})

  };
  return (
    <div className={"page-body-container"}>
      {isSubmitted ? (
        <Alert key={"sdf"} variant={"success"}>
          Your request has been submitted successfully
        </Alert>
      ) : (
        <></>
      )}

      <Row>
      <FontAwesomeIcon  icon={faPhoneSquareAlt}
                color={"#3b8d99"}
                style={{margin:"0 13px",fontSize:"4rem"}}
                className="phoneIcon" />
      <div>
        <h1 style={{margin:"0"}}>Contact Us</h1>
        <p style={{paddingLeft:"10px",color:"lightslategrey"}}>Please fill in this form</p>
        </div>
      </Row>
      

      <Formik
        validationSchema={schema}
        initialValues={{
          Subject: "",
          From: "",
          Body: "",
          phone: "",
          NewsLetter: false
        }}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors
        }) => (
          <FormikForm className={"col-sm-8 offset-sm-2"} noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} md={12} controlId={"Subject"}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  value={values.Subject}
                  onChange={handleChange}
                  required
                  isInvalid={!!touched.Subject && !!errors.Subject}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Subject}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId={"From"}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Email"
                  required
                  value={values.From}
                  onChange={handleChange}
                  isInvalid={!!touched.From && !!errors.From}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.From}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md={6} controlId={"phone"}>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  placeholder="Phone"
                  value={values.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Form.Group controlId="Body">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                required
                value={values.Body}
                onChange={handleChange}
                isInvalid={!!touched.Body && !!errors.Body}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Body}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="checkbox_subscribe">
              <Form.Check
                required
                name="NewsLetter"
                label="Subscribe to our newsletter"
                onChange={handleChange}
                id="validationFormik0"
              />
            </Form.Group>
            <Button variant="primary" type="submit" style={{borderColor:"#3b8d99",backgroundColor:"#3b8d99"}}>
              Submit
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};
export { Enquiries };
