import React, {useEffect, useState} from "react";
import "App.scss";
import "./GalleryVideosPage.scss";
import {Row ,Col ,Accordion,Card, ListGroup} from "react-bootstrap";
import {NetworkHelper} from "modules/network/NetworkHelper";

interface youtubeVideosData {
  Title:string;
  URL:string;
}
interface facebookVideosData {
  Title:string;
  URL:string;
}

const GalleryVideosPage = () => {
  const containerWidth =
    (window?.innerWidth > 500 ? 500 : window.innerWidth) - 50;

  const [youtubeLinks, setYoutubeLinks] = useState<[]>([]);
  const [facebookVideoLinks, setFacebookVideoLinks] = useState<[]>([]);
  const [clickedVideo, setClickedVideo] = useState<string>("");
  const onItemClick = (videoUrl: string) => {
    setClickedVideo(videoUrl);
  };
  useEffect(() => {
    NetworkHelper.post("/Lookups", {
      Lookups: ["Videos"]
    })
      .then(value => {
        if (value.data?.Videos?.youtube?.length) {
          setYoutubeLinks(value.data?.Videos?.youtube);
          console.log(value.data?.Videos?.youtube);
        }
        if (value?.data?.Videos?.facebook?.length) {
          setFacebookVideoLinks(value?.data?.Videos?.facebook);
          console.log(value.data?.Videos?.facebook);
        }
      })
      .catch(reason => {
        console.log("Failed to load video links", reason);
      });
  }, []);
  return (
    <div className={"page-body-container"}>
      <h3 style={{marginBottom:"2rem"}}>Gallery - Videos</h3>
      <Row>
        <Col sm="4" className={'order-last'}>
        <Accordion style={{overflow:"auto",height:"74vh"}}>
  <Card style={{boxShadow:"none",cursor:"pointer"}}>
    <Accordion.Toggle as={Card.Header} eventKey="0">
      Youtube Videos
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <ListGroup>
      {youtubeLinks?.length ? (
            youtubeLinks.map((values:youtubeVideosData , videoIndex: number) => (
             <ListGroup.Item onClick = {() => {onItemClick(`https://www.youtube-nocookie.com/embed/${values.URL}`);}}>
               <span>Youtube Video : {values.Title} </span>
             </ListGroup.Item>
            ))
          ) : (<></>
          )}
          </ListGroup></Card.Body>
    </Accordion.Collapse>
  </Card>
  <Card style={{boxShadow:"none",cursor:"pointer"}}>
    <Accordion.Toggle as={Card.Header} eventKey="1">
      Facebook Videos
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>
      <ListGroup>
        {facebookVideoLinks?.length ? (
          facebookVideoLinks.map((values: facebookVideosData, videoIndex: number) => (
            <ListGroup.Item onClick = {() => {onItemClick(`${values.URL}`);}}>
               <span>{values.Title}</span>
             </ListGroup.Item>
          ))
        
      ) : (
        <></>
      )}</ListGroup>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
</Accordion>
        </Col>
        <Col sm="8">
      <iframe
              key=""
              src={clickedVideo}
              width="100%"
              height="50vh"
              scrolling="no"
              frameBorder="0"
              allow="encrypted-media"
            ></iframe>
      </Col>
      </Row>

      
      
    </div>
  );
};
export { GalleryVideosPage };
