import React, {useEffect, useState} from "react";
import moment from "moment";
import "./CountDownTimerComponent.scss";

export interface CountDownTimerProps {
  timeTillDate: Date;
}
const CountDown = (props: CountDownTimerProps) => {
  const { timeTillDate } = props;
  const [days, setDays] = useState<string>("");
  const [hours, setHours] = useState<string>("");
  const [minutes, setMinutes] = useState<string>("");
  const [seconds, setSeconds] = useState<string>("");
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const _interval: NodeJS.Timeout = setInterval(() => {
      const then: moment.Moment = moment(timeTillDate);
      const now: moment.Moment = moment();
      // @ts-ignore
      const countdown: moment.Moment = moment(then - now);
      const _days = countdown.format("D");
      const _hours = countdown.format("HH");
      const _minutes = countdown.format("mm");
      const _seconds = countdown.format("ss");

      setDays(_days);
      setHours(_hours);
      setMinutes(_minutes);
      setSeconds(_seconds);
    }, 1000);

    setTimerInterval(_interval);

    return function cleanup() {
      if (timerInterval) {
        clearInterval(timerInterval);
        setTimerInterval(undefined);
      }
    };
  }, []);
  if (!seconds) {
    return null;
  }

  return (
    <div>
      {/*<h1>Countdown</h1>*/}
      <div className="countdown-wrapper">
        {days && (
          <div className="countdown-item">
            
            {days}
            <span>days</span>
          </div>
        )}
        {hours && (
          <div className="countdown-item">
            
            {hours}
            <span>hours</span>
          </div>
        )}
        {minutes && (
          <div className="countdown-item">
            
            {minutes}
            <span>minutes</span>
          </div>
        )}
        {seconds && (
          <div className="countdown-item">
            
            {seconds}
            <span>seconds</span>
          </div>
        )}
      </div>
    </div>
  );
};


// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle


export { CountDown };
