import React, {useEffect, useState} from "react";
import "App.scss";
import "./Results.scss";
import {Button, Col, Form, FormControl, InputGroup, Row, Table} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {NetworkHelper} from "modules/network/NetworkHelper";
import * as yup from "yup";
import {Form as FormikForm, Formik} from "formik";
import {ApiLoadedStatus} from "modules/network/ApiLoadedStatus";
import moment from "moment";
import "../style/main.css";
import {GalleryPictureModal} from "pages/Gallery_Pictures/GalleryPictureModal";
//Race & Training results

interface EventsAllTableRowEntry {
  _id: string;
  Category: "Race" | "Training";
  Event: string;
  Date: string;
  Liberation: string;
  Distance: string;
  Status: "NEW" | "Running" | "Finished";
  Image: string; 
}



interface ActionButtonProps {
  buttonType: "Basketing" | "Results";
  eventId: string | number;
}
const ActionButton = (props: ActionButtonProps) => {
  const history = useHistory();
  const { eventId, buttonType } = props;
  const onButtonClick = () => {
    switch (buttonType) {
      case "Basketing":
        history.push(`/results/basketing/${eventId}`);
        break;
      case "Results":
        history.push(`/results/events/${eventId}`);
        break;
      default:
        break;
    }
  };
  return (
    <div onClick={onButtonClick}>
      <div style={{ margin: "0 0.5rem" }}>
        <Button variant="outline-secondary">{buttonType}</Button>
      </div>
    </div>
  );
};


const AllEvents = () => {
  const [isPictureModalVisible, setPictureModalVisible] = useState<boolean>(
    false
  );
  const [clickedImage, setClickedImage] = useState<string>("");
  const [allEvents, setAllEvents] = useState<EventsAllTableRowEntry[]>([]);

  const [apiResponseStatus, setApiResponseStatus] = useState<ApiLoadedStatus>(
    "LOADING"
  );
  const loadEventsFromApi = (searchQuery: string = "") => {
    setApiResponseStatus("LOADING");
    NetworkHelper.get("/Events", { params: { q: searchQuery } })
      .then(value => {
        if (value?.data && value.data?.length) {
          setAllEvents(value.data);
          setApiResponseStatus("LOADED");
        } else {
          setAllEvents([]);
          setApiResponseStatus("NO_RESULTS");
        }
      })
      .catch(reason => {
        console.log("Couldn't load Events data");
        setApiResponseStatus("ERROR");
      });
  };

  useEffect(() => {
    loadEventsFromApi();
  }, []);

  const onImageClick = (imageUrl: string) => {
    setClickedImage(imageUrl);
    setPictureModalVisible(true);
  };

  const schema = yup.object({
    searchField: yup.string()
  });

  const onSearchSubmit = (event: any) => {
    loadEventsFromApi(event.searchField);
  };
  const RenderEventsResults = () => {
    switch (apiResponseStatus) {
      case "ERROR":
        return (
          <div>
            An error has occurred while loading the data, please try again later
          </div>
        );
      case "LOADED":
        return (
          <div style={{ overflowX: "scroll" }}>
            <Table striped hover size="sm" className="table-des">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Event</th>
                  <th>Date</th>
                  <th style={{width:"10%"}}>Image</th>
                  <th>Liberation</th>
                  <th>Distance</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {allEvents.map(
                  (resultItem: EventsAllTableRowEntry, resultIndex: number) => {
                    const {
                      Image,
                      _id,
                      Category,
                      Event,
                      Date,
                      Liberation,
                      Status,
                      Distance
                    } = resultItem;
                    return (
                      <tr key={resultIndex}>
                        <td>{Category}</td>
                        <td>{Event}</td>
                        <td>{moment(Date).format("Do MMMM YYYY")}</td>
                        <td><img alt="thumbnail" src={Image} onClick={() => {
                      onImageClick(Image);
                    }}style={{width:"100%",maxWidth:"80px"}}></img></td>
                        <td>{Liberation}</td>
                        <td>{Distance}</td>
                        <td>{Status}</td>
                        <td>
                          <div className="actionsDiv">
                            <ActionButton 
                              buttonType={"Basketing"}
                              eventId={_id}/>
                            <ActionButton
                              buttonType={"Results"}
                              eventId={_id}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </div>
        );
      case "LOADING":
        return <div className="load-page">
        <div className="load-container">
        <div className="Loader"></div>
        <div className="Loader"></div>
        <div className="Loader"></div>
        </div>
      </div>;
      case "NO_RESULTS":
        return <div>No results found matching your search</div>;
    }
  };
  return (
    <div className={"page-body-container results-page"}>
      <Row style={{ margin: "1.5rem" }}>
        <Col sm={6}>
          <h3>Race & Training Results</h3>
        </Col>
        <Col sm={6}>
          <Formik
            validationSchema={schema}
            initialValues={{
              searchField: ""
            }}
            onSubmit={onSearchSubmit}
          >
            {({ handleSubmit, handleChange, values }) => (
              <FormikForm noValidate onSubmit={handleSubmit}>
                <Col>
                  <Form.Group controlId={"searchField"}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Search"
                        aria-label="Search here"
                        aria-describedby="basic-addon2"
                        value={values.searchField}
                        onChange={handleChange}
                      />
                      <InputGroup.Append>
                        <Button variant="outline-secondary" type={"submit"}>
                          Search
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
      <RenderEventsResults />
      <GalleryPictureModal
        show={isPictureModalVisible}
        imageUrl={clickedImage}
        onHide={() => setPictureModalVisible(false)}
      />
    </div>
  );
};
export { AllEvents };
