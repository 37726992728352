/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d4f4e380-6599-4020-b80a-6a58d15c8f96",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_E853O6JBc",
    "aws_user_pools_web_client_id": "3l1kgl6resa68l6j5v464n59t0",
    "oauth": {}
};


export default awsmobile;
