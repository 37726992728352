import {Datagrid, List,DateField,TextField,} from "react-admin";
import React from "react";



const Email = (props: any) => {
  console.log("Mails", props);
  return (
    <List {...props} pagination={false}>
      <Datagrid rowClick={"edit"}>
        <TextField source="Subject" sortable={false} />
        <TextField source="From" sortable={false} />
        <DateField source="Date" sortable={false} />
        <TextField source="Body" sortable={false} />
      </Datagrid>
    </List>
  );
};
export { Email };
